@import url(https://fonts.googleapis.com/css2?family=Inter);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: 'Inter', Arial, Helvetica, sans-serif;;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

h1,h2,h3,h4,h5,h6,p,a,input,ul,li,span {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

h2 {
  text-align: center;
  font-size: 25px;
  padding-top: 20px;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;;
  font-weight: 600;
  color: var(--black);
}

h2::after {
  content: "";
  border: 2px solid #2c3e50;
  width: 25px;
  height: 1px;
  display: block;
  margin: 2px auto;
}

button {
  font-family: 'Cooper Hewitt', 'Inter', Arial, Helvetica, sans-serif;;
}

img {
  width: 100%;
  height: auto;
  margin: 40px auto 80px auto;
  display: inline;
  display: block;
}

body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  height: 40px;
}

input, textarea {
  border: darkgray solid thin;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: white;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: white;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: white;
}

/* Position Footer at Bottom of Page */
body {
  position: relative;
  min-height: 100vh;
  background-image: url(/static/media/mural-background.25029414.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

#root {
  padding-bottom: 5rem;
}

@media ( min-width: 992px ) {
  a:hover {
    color: var(--blue-hover);
  }
}



@font-face {
  font-family: "Cooper Hewitt";
  src: url(/static/media/CooperHewitt-Book.375f05aa.otf) format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url(/static/media/CooperHewitt-Medium.da25a0c1.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url(/static/media/CooperHewitt-Semibold.b5da7aed.otf) format("opentype");
  font-weight: bold;
}

:root {
  --black: #585858;
  --blue: #4D84CB;
  --blue-hover: #4474b3;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #4D84CB;
  color: var(--blue);
}

.loader-circle {
  border: 5px solid #ddd;
  border-radius: 50%;
  border-top:5px solid #4D84CB;
  border-top:5px solid var(--blue);
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

.white-backdrop {
  background: white;
}

.loading-container {
  background: white;
  height: 80vh;
}

.hide {
  display: none;
}

.breadcrumbs {
  text-transform: uppercase;
  display: block;
  text-align: center;
}

.breadcrumb-divider {
  font-weight: 600;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
}

.breadcrumbs a {
  font-weight: 200;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
  letter-spacing: .1em;
}

.breadcrumbs span {
  font-weight: 600;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
  letter-spacing: .1em;
  color: #585858;
  color: var(--black);
}

.bio-post-content p, .project-summary p {
  padding: 10px 0;
}


@media ( min-width: 768px ) {
  .breadcrumbs {
    margin: 0 20px;
    text-align: right;
  }
}

@media ( min-width: 992px ) {
  .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #585858;
    color: var(--black);
  }

  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #4474b3;
    color: var(--blue-hover);
  }

  .social-icons-a:hover path, .social-icons-a:hover polygon, .social-icons-a:hover circle {
      fill: #4474b3;
      fill: var(--blue-hover);;
  }
}


@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-right {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes spin-right {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}


a {
    color: var(--black);
}

.navbar {
    display: block;
}

.link-to {
    padding: 7px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: 'Cooper Hewitt', 'Inter', 'Arial';
}

.navigation {
    display: block;
    background: white;
    transition: .2s;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    box-shadow: 5px 5px 10px rgba(0,0,0,.3);
    position: -webkit-sticky;
    position: sticky;
}

.navigation-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
}

.logo {
    margin: auto;
    width: 55px;
    padding: 7px 7px 7px 0;
}

.navbar-toggler {    
    position: absolute;
    right: 10px;    
    border: none;
    height: 44px;
    border-radius: 0;
}

.global-navbar .navbar-toggler {
    position: -webkit-sticky;
    position: sticky;
    padding-right: 12px;
    margin-right: -12px;
}

.navbar-toggler-icon {
    fill: black;
    opacity: 1;
    color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 1);
}

.toggle-icon-line {
    height: 3px;
    width: 27px;
    background: var(--black);;
    display: block;
    margin: 5px 0;
    transition:.2s;
}

.collapsed .toggle-icon-line {
    background: white;
}

.global-navbar .navbar-toggler.collapsed {
    position: -webkit-sticky;
    position: sticky;
    z-index: 30000;
}

.collapsed .toggle-icon-line {
    color: white;
    z-index: 3000;
}

.collapsed .toggle-line-top {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    top: 15px;
}

.collapsed .toggle-line-middle {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.collapsed .toggle-line-bottom {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    top: 15px;
}

.navigation-text {
    display: -webkit-flex;
    display: flex;
    font-size: 35px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-transform: uppercase;
    font-weight: 400;
    max-width: 1200px;
    margin: auto;
}

.navbar-light .navbar-nav .nav-link a {
    color: rgba(0,0,0,1);
}

.navigation-h1 {
    font-size: 25px;
    font-weight: 600;
}

.nav-bottom-div {
    display: -webkit-flex;
    display: flex;
    border-top: solid 7px #2c3e50;
    -webkit-align-items: center;
            align-items: center;
    width: 75%;
}

.navigation-p {
    margin-top: 0;
    padding-top: 12px;
    text-transform: uppercase;
    font-size: 13px;
}

.navigation-ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.navigation-ul-li {
    list-style: none;
    padding: 0 7px;
    font-size: 20px;
}

.mobile-social-icons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center
}

.desktop-social-icons {
    display: none;
}

.navbar-toggler:focus {
    background: #2c3e50;
    outline: none;
}

.navbar-toggler:focus .toggle-icon-line {
    background: white;
}

.show .navbar-nav {
    background:var(--black);;
    color: white;
    border: none;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 40px;
    padding-top: 100px;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
}

@media (min-width: 768px) {

    .navbar-nav {
        text-align: center;
    }

    .mobile-social-icons {
        -webkit-justify-content: center;
                justify-content: center
    }

    .navigation-h1 {
        font-size:35px;
    }

    .nav-bottom-div, .navbar-nav {
        width: 87%;
    }

    .navigation-p {
        font-size: 18px;
    }

    .navigation-h1-span {
        transition: .5s;
    }

    .nav-link-home:hover {
        color: #000;
        color: initial;
    }

    .navigation-h1:hover .navigation-h1-span {
        color:#346b95;
    }

}

@media (min-width: 992px) {

    .show .navbar-nav {
        background: transparent;
        position: static;
        -webkit-flex-direction: row;
                flex-direction: row;
        padding: 0;
        padding: initial;
        padding-top: inherit;
    }

    .navbar-nav {
        border: none;
    }

    .navigation-text {
        display: -webkit-flex;
        display: flex;
    }

    .navbar-nav {
        background: transparent;
        width: 100%;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .navigation-p {
        width: 100%;
    }

    .nav-bottom-div {
        width: 100%
    }

    .navbar-expand-lg .navbar-collapse {
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }

    /* Override bootstrap height 0 that they inject inline into the DOM */
    .navbar-collapse {
        height: auto !important;
    }

    .link-to {
        padding: 5px 0px 0px 25px;
        font-size: 20px; 
        color: var(--black);
    }

    .bottom-nav {
        display: -webkit-flex;
        display: flex;
    }

    .mobile-social-icons { 
        display: none;
    }

    .desktop-social-icons {
        display: -webkit-flex;
        display: flex;
    }

    .social-icons-a {
        font-size: 25px;
        padding-left: 20px;
    }
}

.footer {
    position: absolute;
    width: 100%; 
    height: 11.5rem;
    overflow: hidden;
    bottom: -150px;
}

.footer-span {
    position: absolute;
    font-family:'Cooper Hewitt', 'Inter', 'Arial';
    text-align: center;
    left: 0;
    right: 0;
    top: 80%;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.footer-img {
    width: 100%;
    max-width: 500px;
    -webkit-align-self: center;
            align-self: center;
    margin: auto;
}

@media (min-width: 580px) {

    .footer {
        height: 15.5rem;
    }

    .footer-span {
        top: 90%;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.navbar-collapse {
    position: absolute;
    left: -400px;
} 

.collapsing {
    position: absolute;
    height: auto;
    overflow: visible;
    overflow: initial;
    transition: none;
}

.home-navbar-link-container {
    -webkit-justify-content: initial;
            justify-content: initial;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.home-navigation .navbar-toggler {
    top: 6px;
    right: 10px;
}

.home-navigation .navbar-toggler.collapsed {
    position: fixed;
    z-index: 30000;
}

.home-navbar-link-container {
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.logo-text {
    font-size: 20px;
    text-align: center;
    font-family: 'Cooper Hewitt';
    color: var(--black);
    text-transform: none;
    letter-spacing: 1.65px;
}

.home-nav-link-to {
    padding: 7px;
    font-weight: 600;
    font-size: 35px;
    font-family: 'Cooper Hewitt', 'Inter', Arial, Helvetica, sans-serif;;
}

.home-navigation {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 20px;
    background: transparent;
    position: static;
    z-index: 100000;
    box-shadow: none;
    max-width: 1000px;
}

.home-nav-link-home {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    margin: 40px auto
}

.home-nav-link-home:after {
    content: "";
    width: 205px;
    height: 9px;
    background: #575757;
    position: absolute;
    bottom: 37px;
}

.home-navigation-text .navbar-toggler {
    position: absolute;
    right: 0;
    top: 20px;
    right: 20PX;
    border: none;
    height: 44px;
    border-radius: 0;
}

.home-nav-logo {
    width: 205px;
    height: 217px;
    font-weight: bold;
}

.home-navigation-text {
    font-size: 35px;
    color: var(--black);
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-transform: uppercase;
    font-weight: 400;
    margin: auto;
}

.home-nav-social-icons {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-top: 60px;
    font-size: 35px;
}

.home-nav-social-icons svg {
    width: 40px;
    height: 40px;
}

.home-nav-social-icons path, .home-nav-social-icons polygon, .home-nav-social-icons circle {
    fill: white;
}

.social-icons-a {
    color: white;
    padding: 0 20px;
}

.navbar-nav {
    left: -400px;
    transition-duration: inherit;
} 

@media (min-width: 540px) {

    .home-nav-link-container {
        text-align: center;
    }

}

@media (min-width: 768px) {

    .home-navigation {
        position: static;
        box-shadow: none;
        background: transparent;
    }

    .home-navigation-text .navbar-toggler {
        top: 50px;
        right: 30px;
    }

    .home-nav-social-icons {
        width: 100%;
    }

    .home-nav-link-home:hover {
        color: #000;
        color: initial;
    }

}

@media (min-width: 992px) {

    .navbar-light .navbar-nav .nav-link {
        color: var(--black);
    }

    .home-navbar-link-container {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .home-navigation {
        margin: 40px auto;
        width: 100%;
    }

    .home-nav-logo {
        margin: auto auto auto 0;
    }

    .home-nav-link-home {
        margin: 0;
        margin: initial;
    }

    .home-navigation-text {
        display: -webkit-flex;
        display: flex;
        width: 100%;
    }

    .home-navbar-nav {
        background: transparent;
        width: 100%;
        -webkit-align-items: flex-end;
                align-items: flex-end;
    }

    .navbar-expand-lg .home-navbar-nav.navbar-nav {
        -webkit-flex-direction: column;
                flex-direction: column;
    } 

    /* Override bootstrap height 0 that they inject inline into the DOM */
     .navbar-collapse {
        height: auto !important;
        position: static;
    } 

    .home-nav-link-container {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .home-navigation .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 1rem;
    } 

    .home-nav-link-to {
        padding: 0 .5rem .5rem .5rem;
        font-size: 25px; 
        color: var(--black);
    }

    .home-nav-social-icons {
        margin-top: 0;
        -webkit-justify-content: center;
                justify-content: center;
        width: 100%;
    }

    .home-nav-social-icons svg {
        height: 30px;
        width: 30px;
        opacity: .75;
    }

    .home-nav-social-icons path, .home-nav-social-icons polygon, .home-nav-social-icons circle {
        fill: var(--black);
    }

}

.hero-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    max-width: 960px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.headshot, .seattle {
    height: 450px;
    background: url(/static/media/timelapse-headshot-compressed.b822a019.jpg);
    background-size: cover;
    background-position: center center;
    width: 100%;
}

.seattle {
    background: url(/static/media/sea1.c00d41b5.jpg);
    background-size: cover;
    background-position: center center;
    width: 100%;
    margin-top: 20px;
}

@media (min-width: 768px) {

    .hero-container {
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-top: 0;
    }

    .headshot, .seattle{
        width: 50%;
        height: 350px;
    }

    .headshot {
        margin-top: 0;
    }

    .seattle {
        margin-top: 0;
        margin-top: initial;
        margin-right: 20px;
    }

    .about-video {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        z-index: 1;
    }

}

@media (min-width: 992px) {
    .hero-container {
        margin: auto;
    }

}

path {
    fill: #e8e8e8;
}
.technology-list-section {
    background: blue;
    background:#2c3e50;
    background:linear-gradient(
         rgba(0, 0, 0, 0.25),
         rgba(0, 0, 0, 0.25) ),
          url(/static/media/mural.f2038a72.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    background-position: center;
}

.tech-list-ul {
    display: grid;
    -webkit-justify-content: center;
            justify-content: center;
    grid-template-columns: auto auto;
    list-style: none;
    padding:  40px 0;
    max-width: 1150px;
    margin: auto;  
}

.tech-svg {
    margin: 20px;
}

.tech-svg svg {
    height: 125px;
    max-width: 120px;
}

@media (min-width: 580px) {
    .tech-list-ul {
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }

}

@media (min-width: 768px) {

    .tech-list-ul {
        grid-template-columns: auto auto auto auto auto;     
    }

    .tech-svg svg {
        width: 100%;
        height: 110px;
    }
  
}
.work-section {
    /* padding: 40px 0; */
    text-align: center;
    position: relative;
    min-height: 500px;
    max-width: 1200px;
    margin: auto;
}

.work-gallery {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 60px;
    padding: 0 20px;
    margin-top: 60px;
}

.work-gallery-div {
    transition: .4s;
    margin: auto auto 20px auto;
    text-align: center;
    position: relative;
    overflow: hidden;
    max-width: 220px;
}

.work-gallery-img-div {
    transition: .4s;
}

.work-gallery-img {
    max-width: 100%;    
}

.work-gallery-project-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80px;
    transition: .5s;
}

.work-gallery-project-name {
    font-size: 16px;
    font-weight: 800;
    font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin: 10px 5px 0 5px;
    color: var(--black);
    text-transform: uppercase;
}

.work-gallery-project-desc {
    font-size: 16px;
    font-weight: 200;
    display: block;
    padding: 0 5px 0px 5px;
    color: var(--black);
    margin-bottom: 10px;
}

@media (min-width: 580px) {
    .work-gallery {
        grid-template-columns: 1fr 1fr;
    } 

    .work-gallery-div {
        max-width: none;
    }
}

@media (min-width: 768px) {

    .work-gallery {
        margin-top: 0;
    }

    .work-gallery-img {
        width: 70%;
        margin: 40px auto;
    }

    .work-gallery-div:hover {
        -webkit-transform: scale(1.02);
                transform: scale(1.02);
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
    }

    .work-gallery-div:hover .work-gallery-project-text {
        bottom: 0;
    }

    .work-gallery-div:hover .work-gallery-project-name {
        color: var(--blue);
        transition: .5s;
    }

}

@media (min-width: 992px) {

    .work-gallery {
        grid-template-columns: 1fr 1fr 1fr;
    }

}

.contact-section {
    padding: 40px 0;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

form {
    text-align: center;
    margin: 20px;
    padding: 0px 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    max-width: 500px;
    width: 100%;
}

.form-label {
    text-transform: uppercase;
    font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding-bottom: 0;
    margin-bottom: 0;
    color: var(--black);;
}
  
.contact-section input, .contact-section textarea {
    display: block;
    margin: 0px auto 20px auto;
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 30%) inset;
    border: 1px #e0e0e0 solid;
    border-radius: 1px;
    height: 45px;
}

.contact-section textarea {
    height: 150px;
}

.contact-button-container {
    -webkit-align-self: center;
            align-self: center;
    width: 100%;
}

form .submit-button {
    min-width: 100%;
    border: none;
    background-color: var(--blue);
    color: white;
    text-align: center;
    margin: 20px auto 0 auto;
    display: block;
    padding: 12px 10px;
    border-radius: 0px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
    height: 45px;
}

@media (min-width: 768px) {
    input, textarea {
        max-width: 500px;
    }

    form .submit-button {
        min-width: 10rem;
    }

    .submit-button:hover {
        text-decoration: none;
        background-color: var(--blue-hover);
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
        transition: .3s;
    }
}
.kaleidoscope-mountain-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.kaleidoscope-mountain {
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40% 55%;
    padding: 100px 0px;
    width: 100%;
}

.mtns-lake-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    -webkit-animation: spin 200s linear infinite;
            animation: spin 200s linear infinite;
}

.mnt-lake-img-inside {
    -webkit-animation: spin-right 100s linear infinite;
            animation: spin-right 100s linear infinite;
    width: 75%;
}

.kaleidoscope-mountain-p-background {
    display: -webkit-flex;
    display: flex;
}

.kaleidoscope-mountain-p {
    padding: 60px 20px;
    margin: auto;
    z-index: 200;
    text-shadow: 1px 1px 30px black;
}

.kaleidoscope-mountain-p p, .kaleidoscope-mountain-p span {
    margin-bottom: 0;
    line-height: 1;
    font-size: 2.5em;
    text-transform: uppercase;
    font-family: 'Cooper Hewitt', Arial, Helvetica, sans-serif;;
    font-weight: bold;
    color: white;
}


@media (min-width: 540px) {

    .kaleidoscope-mountain-container {
        padding: 100px 0;
    }
    
    .kaleidoscope-mountain-p p, .kaleidoscope-mountain-p span {
        font-size: 3em;
    }
}

@media (min-width: 768px) {

    .kaleidoscope-mountain-container {
        -webkit-align-items: center;
                align-items: center;
        background-image: linear-gradient(to bottom, rgba(255,255,255,0.001) 0%, white);
    }

    .kaleidoscope-mountain-p {
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-top: 0;
    }

    .kaleidoscope-mountain {
        width: 80%;
        min-height: 490px;
        max-width: 640px;
    }

    .kaleidoscope-mountain-p {
        padding: 20px;
    }

    .kaleidoscope-mountain-p p, .kaleidoscope-mountain-p span {
        font-size: 3em;
    }
}



.bio-container {
    background: white;
}

.bio-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 1200px;
    margin: 60px auto auto auto;
}

.bio-content-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 40px auto auto auto
}

.bio-h2 {
    margin-top: 20px;
}

.bio-image-div img {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
} 

.content-link-wrapper {
    width: 100%;
}

.bio-post-content {
    margin: 40px auto;
    padding: 0 20px;
}

.link-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 80px;
}

.link-wrapper a {
    padding: 0 30px;
}

.link-wrapper svg {
    height: 40px;
    width: 40px;
}

.link-wrapper path, .link-wrapper polygon, .link-wrapper circle {
    fill:var(--black);
    opacity: .75;
}

@media (min-width: 768px) {

    .bio-section {
        margin-top: 60px;
    }

    .bio-content-wrapper {
        max-width: 700px;
    }

    .bio-h2 {
        margin-top: 0;
    }

    .bio-image-div img {
        margin-top: 40px;
        margin-right: 10px;
    } 

    .bio-post-content {
        margin-top: 40px;
        margin-left: 10px;
        padding-left: 0;
    }

}

@media (min-width: 992px) {
    .bio-content-wrapper {
        -webkit-flex-direction: row;
                flex-direction: row;
        max-width: none;
        max-width: initial;
    }

    .bio-image-div img {
        width: 93%;
        margin-bottom: 80px;
    }
}
.individual-project {
    background: white;
}

.individual-project-main {
    max-width: 1200px;
    margin: 60px auto auto auto;
}

.project-post-content a {
    min-width: 8rem;
    border: none;
    background-color: var(--blue);
    color: white;
    text-align: center;
    margin: 10px 20px;
    display: block;
    border-radius: 0px;
    font-size: 19px;
    text-transform: uppercase;
    padding: 12px 10px;
    font-family: 'Cooper Hewitt', 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
    width: 220px;
    height: 45px;
}

.project-img {
    margin-top: 0;
    margin-bottom: 0;
    width: 70%;
}

.project-img-and-title-section {
    margin: 0 40px 40px 40px;
    text-align: center;
}

.tech-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}
.dev-icon {
    font-size: 40px;
    padding: 0 5px;
    margin: 5px 0;
    color: var(--black);;
    opacity: .7;
}

.project-img-section div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
}

.project-img-section img {
    min-height: 200px;
    min-width: 350px;
    margin: 40px auto;
}

.project-img-section h3 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    color: #585858;
    text-transform: uppercase;
    font-family: 'Cooper Hewitt';
}

.project-img-section p {
    display: grid;
    margin: 10px auto;
    text-align: left; 
    padding: 10px;
}

.project-img-and-title-section a {
    background: var(--blue);
    color: white;
    border-radius: 20px;
    display: block;
}

@media (min-width:768px) {

    .individual-project-main {
        margin-top: 60px;
    }

    .project-img-section {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        max-width: 1000px;
        margin: 0 auto 80px auto;
    }

    .project-img-section img {
        max-width: 750px;
        padding: 0;
        width: 80%;
    }

    .project-img-section div {
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .project-post-content {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .project-img-and-title-section {
        margin: 0 40px;
        text-align: left;
    }
    
    .tech-list {
        -webkit-justify-content: center;
                justify-content: center;
    }

    .project-img {
        width: 30%;
    }

    .project-post-content a:hover {
        text-decoration: none;
        background-color: var(--blue-hover);
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
        transition: .3s;
    }
}

@media (min-width: 900px) {

    .fixed-container {
        width: 40%;
        max-width: 500px;
        position: fixed;
    }

    .project-img-and-title-section {
        margin: 0 auto;
        max-width: 850px;
    }
}
.four-oh-four-err {
    font-size: 40px;
    text-align: center;
    margin-top: 200px;
}

.four-oh-four-text {
    font-size: 16px;
    text-align: center;
    padding: 20px;
}

.iframe-404 {
    display: block;
    margin: 0 auto 80px auto;
    height: 300px;
}

@media (min-width: 768px) {
    .four-oh-four-err {
        margin-top: 60px;
    }
}
