@import url('https://fonts.googleapis.com/css2?family=Inter');

@font-face {
  font-family: "Cooper Hewitt";
  src: url("./assets/fonts/cooper_hewitt/CooperHewitt-Book.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("./assets/fonts/cooper_hewitt/CooperHewitt-Medium.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("./assets/fonts/cooper_hewitt/CooperHewitt-Semibold.otf") format("opentype");
  font-weight: bold;
}

:root {
  --black: #585858;
  --blue: #4D84CB;
  --blue-hover: #4474b3;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--blue);
}

.loader-circle {
  border: 5px solid #ddd;
  border-radius: 50%;
  border-top:5px solid var(--blue);
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

.white-backdrop {
  background: white;
}

.loading-container {
  background: white;
  height: 80vh;
}

.hide {
  display: none;
}

.breadcrumbs {
  text-transform: uppercase;
  display: block;
  text-align: center;
}

.breadcrumb-divider {
  font-weight: 600;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
}

.breadcrumbs a {
  font-weight: 200;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
  letter-spacing: .1em;
}

.breadcrumbs span {
  font-weight: 600;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
  letter-spacing: .1em;
  color: var(--black);
}

.bio-post-content p, .project-summary p {
  padding: 10px 0;
}


@media ( min-width: 768px ) {
  .breadcrumbs {
    margin: 0 20px;
    text-align: right;
  }
}

@media ( min-width: 992px ) {
  .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: var(--black);
  }

  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: var(--blue-hover);
  }

  .social-icons-a:hover path, .social-icons-a:hover polygon, .social-icons-a:hover circle {
      fill: var(--blue-hover);;
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

