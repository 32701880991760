.contact-section {
    padding: 40px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

form {
    text-align: center;
    margin: 20px;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
}

.form-label {
    text-transform: uppercase;
    font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding-bottom: 0;
    margin-bottom: 0;
    color: var(--black);;
}
  
.contact-section input, .contact-section textarea {
    display: block;
    margin: 0px auto 20px auto;
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 30%) inset;
    border: 1px #e0e0e0 solid;
    border-radius: 1px;
    height: 45px;
}

.contact-section textarea {
    height: 150px;
}

.contact-button-container {
    align-self: center;
    width: 100%;
}

form .submit-button {
    min-width: 100%;
    border: none;
    background-color: var(--blue);
    color: white;
    text-align: center;
    margin: 20px auto 0 auto;
    display: block;
    padding: 12px 10px;
    border-radius: 0px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
    height: 45px;
}

@media (min-width: 768px) {
    input, textarea {
        max-width: 500px;
    }

    form .submit-button {
        min-width: 10rem;
    }

    .submit-button:hover {
        text-decoration: none;
        background-color: var(--blue-hover);
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
        transition: .3s;
    }
}