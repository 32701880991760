.work-section {
    /* padding: 40px 0; */
    text-align: center;
    position: relative;
    min-height: 500px;
    max-width: 1200px;
    margin: auto;
}

.work-gallery {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 60px;
    padding: 0 20px;
    margin-top: 60px;
}

.work-gallery-div {
    transition: .4s;
    margin: auto auto 20px auto;
    text-align: center;
    position: relative;
    overflow: hidden;
    max-width: 220px;
}

.work-gallery-img-div {
    transition: .4s;
}

.work-gallery-img {
    max-width: 100%;    
}

.work-gallery-project-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80px;
    transition: .5s;
}

.work-gallery-project-name {
    font-size: 16px;
    font-weight: 800;
    font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;
    display: inline-flex;
    margin: 10px 5px 0 5px;
    color: var(--black);
    text-transform: uppercase;
}

.work-gallery-project-desc {
    font-size: 16px;
    font-weight: 200;
    display: block;
    padding: 0 5px 0px 5px;
    color: var(--black);
    margin-bottom: 10px;
}

@media (min-width: 580px) {
    .work-gallery {
        grid-template-columns: 1fr 1fr;
    } 

    .work-gallery-div {
        max-width: none;
    }
}

@media (min-width: 768px) {

    .work-gallery {
        margin-top: 0;
    }

    .work-gallery-img {
        width: 70%;
        margin: 40px auto;
    }

    .work-gallery-div:hover {
        transform: scale(1.02);
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
    }

    .work-gallery-div:hover .work-gallery-project-text {
        bottom: 0;
    }

    .work-gallery-div:hover .work-gallery-project-name {
        color: var(--blue);
        transition: .5s;
    }

}

@media (min-width: 992px) {

    .work-gallery {
        grid-template-columns: 1fr 1fr 1fr;
    }

}
