.individual-project {
    background: white;
}

.individual-project-main {
    max-width: 1200px;
    margin: 60px auto auto auto;
}

.project-post-content a {
    min-width: 8rem;
    border: none;
    background-color: var(--blue);
    color: white;
    text-align: center;
    margin: 10px 20px;
    display: block;
    border-radius: 0px;
    font-size: 19px;
    text-transform: uppercase;
    padding: 12px 10px;
    font-family: 'Cooper Hewitt', 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
    width: 220px;
    height: 45px;
}

.project-img {
    margin-top: 0;
    margin-bottom: 0;
    width: 70%;
}

.project-img-and-title-section {
    margin: 0 40px 40px 40px;
    text-align: center;
}

.tech-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.dev-icon {
    font-size: 40px;
    padding: 0 5px;
    margin: 5px 0;
    color: var(--black);;
    opacity: .7;
}

.project-img-section div {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.project-img-section img {
    min-height: 200px;
    min-width: 350px;
    margin: 40px auto;
}

.project-img-section h3 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    color: #585858;
    text-transform: uppercase;
    font-family: 'Cooper Hewitt';
}

.project-img-section p {
    display: grid;
    margin: 10px auto;
    text-align: left; 
    padding: 10px;
}

.project-img-and-title-section a {
    background: var(--blue);
    color: white;
    border-radius: 20px;
    display: block;
}

@media (min-width:768px) {

    .individual-project-main {
        margin-top: 60px;
    }

    .project-img-section {
        display: flex;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto 80px auto;
    }

    .project-img-section img {
        max-width: 750px;
        padding: 0;
        width: 80%;
    }

    .project-img-section div {
        flex-direction: row;
    }

    .project-post-content {
        display: flex;
        flex-direction: column;
    }

    .project-img-and-title-section {
        margin: 0 40px;
        text-align: left;
    }
    
    .tech-list {
        justify-content: center;
    }

    .project-img {
        width: 30%;
    }

    .project-post-content a:hover {
        text-decoration: none;
        background-color: var(--blue-hover);
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
        transition: .3s;
    }
}

@media (min-width: 900px) {

    .fixed-container {
        width: 40%;
        max-width: 500px;
        position: fixed;
    }

    .project-img-and-title-section {
        margin: 0 auto;
        max-width: 850px;
    }
}