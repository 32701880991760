.bio-container {
    background: white;
}

.bio-section {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 60px auto auto auto;
}

.bio-content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 40px auto auto auto
}

.bio-h2 {
    margin-top: 20px;
}

.bio-image-div img {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
} 

.content-link-wrapper {
    width: 100%;
}

.bio-post-content {
    margin: 40px auto;
    padding: 0 20px;
}

.link-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.link-wrapper a {
    padding: 0 30px;
}

.link-wrapper svg {
    height: 40px;
    width: 40px;
}

.link-wrapper path, .link-wrapper polygon, .link-wrapper circle {
    fill:var(--black);
    opacity: .75;
}

@media (min-width: 768px) {

    .bio-section {
        margin-top: 60px;
    }

    .bio-content-wrapper {
        max-width: 700px;
    }

    .bio-h2 {
        margin-top: 0;
    }

    .bio-image-div img {
        margin-top: 40px;
        margin-right: 10px;
    } 

    .bio-post-content {
        margin-top: 40px;
        margin-left: 10px;
        padding-left: 0;
    }

}

@media (min-width: 992px) {
    .bio-content-wrapper {
        flex-direction: row;
        max-width: initial;
    }

    .bio-image-div img {
        width: 93%;
        margin-bottom: 80px;
    }
}