html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: 'Inter', Arial, Helvetica, sans-serif;;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

h1,h2,h3,h4,h5,h6,p,a,input,ul,li,span {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

h2 {
  text-align: center;
  font-size: 25px;
  padding-top: 20px;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Cooper Hewitt','Inter', Arial, Helvetica, sans-serif;;
  font-weight: 600;
  color: var(--black);
}

h2::after {
  content: "";
  border: 2px solid #2c3e50;
  width: 25px;
  height: 1px;
  display: block;
  margin: 2px auto;
}

button {
  font-family: 'Cooper Hewitt', 'Inter', Arial, Helvetica, sans-serif;;
}

img {
  width: 100%;
  height: auto;
  margin: 40px auto 80px auto;
  display: inline;
  display: block;
}

body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  height: 40px;
}

input, textarea {
  border: darkgray solid thin;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: white;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: white;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: white;
}

/* Position Footer at Bottom of Page */
body {
  position: relative;
  min-height: 100vh;
  background-image: url('../src/assets/mural-background.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

#root {
  padding-bottom: 5rem;
}

@media ( min-width: 992px ) {
  a:hover {
    color: var(--blue-hover);
  }
}


