.hero-container {
    display: flex;
    flex-direction: column-reverse;
    max-width: 960px;
    justify-content: center;
    align-items: center;
}

.headshot, .seattle {
    height: 450px;
    background: url('../assets/timelapse-headshot-compressed.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
}

.seattle {
    background: url('../assets/sea1.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    margin-top: 20px;
}

@media (min-width: 768px) {

    .hero-container {
        flex-direction: row;
        margin-top: 0;
    }

    .headshot, .seattle{
        width: 50%;
        height: 350px;
    }

    .headshot {
        margin-top: 0;
    }

    .seattle {
        margin-top: initial;
        margin-right: 20px;
    }

    .about-video {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        z-index: 1;
    }

}

@media (min-width: 992px) {
    .hero-container {
        margin: auto;
    }

}
