a {
    color: var(--black);
}

.navbar {
    display: block;
}

.link-to {
    padding: 7px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: 'Cooper Hewitt', 'Inter', 'Arial';
}

.navigation {
    display: block;
    background: white;
    transition: .2s;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    box-shadow: 5px 5px 10px rgba(0,0,0,.3);
    position: sticky;
}

.navigation-text {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.logo {
    margin: auto;
    width: 55px;
    padding: 7px 7px 7px 0;
}

.navbar-toggler {    
    position: absolute;
    right: 10px;    
    border: none;
    height: 44px;
    border-radius: 0;
}

.global-navbar .navbar-toggler {
    position: sticky;
    padding-right: 12px;
    margin-right: -12px;
}

.navbar-toggler-icon {
    fill: black;
    opacity: 1;
    color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 1);
}

.toggle-icon-line {
    height: 3px;
    width: 27px;
    background: var(--black);;
    display: block;
    margin: 5px 0;
    transition:.2s;
}

.collapsed .toggle-icon-line {
    background: white;
}

.global-navbar .navbar-toggler.collapsed {
    position: sticky;
    z-index: 30000;
}

.collapsed .toggle-icon-line {
    color: white;
    z-index: 3000;
}

.collapsed .toggle-line-top {
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
}

.collapsed .toggle-line-middle {
    transform: rotate(45deg);
}

.collapsed .toggle-line-bottom {
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
}

.navigation-text {
    display: flex;
    font-size: 35px;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 400;
    max-width: 1200px;
    margin: auto;
}

.navbar-light .navbar-nav .nav-link a {
    color: rgba(0,0,0,1);
}

.navigation-h1 {
    font-size: 25px;
    font-weight: 600;
}

.nav-bottom-div {
    display: flex;
    border-top: solid 7px #2c3e50;
    align-items: center;
    width: 75%;
}

.navigation-p {
    margin-top: 0;
    padding-top: 12px;
    text-transform: uppercase;
    font-size: 13px;
}

.navigation-ul {
    display: flex;
    align-items: center;
}

.navigation-ul-li {
    list-style: none;
    padding: 0 7px;
    font-size: 20px;
}

.mobile-social-icons {
    display: flex;
    justify-content: center
}

.desktop-social-icons {
    display: none;
}

.navbar-toggler:focus {
    background: #2c3e50;
    outline: none;
}

.navbar-toggler:focus .toggle-icon-line {
    background: white;
}

.show .navbar-nav {
    background:var(--black);;
    color: white;
    border: none;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 100px;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
}

@media (min-width: 768px) {

    .navbar-nav {
        text-align: center;
    }

    .mobile-social-icons {
        justify-content: center
    }

    .navigation-h1 {
        font-size:35px;
    }

    .nav-bottom-div, .navbar-nav {
        width: 87%;
    }

    .navigation-p {
        font-size: 18px;
    }

    .navigation-h1-span {
        transition: .5s;
    }

    .nav-link-home:hover {
        color: initial;
    }

    .navigation-h1:hover .navigation-h1-span {
        color:#346b95;
    }

}

@media (min-width: 992px) {

    .show .navbar-nav {
        background: transparent;
        position: static;
        flex-direction: row;
        padding: initial;
        padding-top: inherit;
    }

    .navbar-nav {
        border: none;
    }

    .navigation-text {
        display: flex;
    }

    .navbar-nav {
        background: transparent;
        width: 100%;
        justify-content: flex-end;
        flex-direction: row;
    }

    .navigation-p {
        width: 100%;
    }

    .nav-bottom-div {
        width: 100%
    }

    .navbar-expand-lg .navbar-collapse {
        justify-content: flex-end;
    }

    /* Override bootstrap height 0 that they inject inline into the DOM */
    .navbar-collapse {
        height: auto !important;
    }

    .link-to {
        padding: 5px 0px 0px 25px;
        font-size: 20px; 
        color: var(--black);
    }

    .bottom-nav {
        display: flex;
    }

    .mobile-social-icons { 
        display: none;
    }

    .desktop-social-icons {
        display: flex;
    }

    .social-icons-a {
        font-size: 25px;
        padding-left: 20px;
    }
}
