.navbar-light .navbar-nav .nav-link {
    color: white;
}

.navbar-collapse {
    position: absolute;
    left: -400px;
} 

.collapsing {
    position: absolute;
    height: auto;
    overflow: initial;
    transition: none;
}

.home-navbar-link-container {
    justify-content: initial;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.home-navigation .navbar-toggler {
    top: 6px;
    right: 10px;
}

.home-navigation .navbar-toggler.collapsed {
    position: fixed;
    z-index: 30000;
}

.home-navbar-link-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.logo-text {
    font-size: 20px;
    text-align: center;
    font-family: 'Cooper Hewitt';
    color: var(--black);
    text-transform: none;
    letter-spacing: 1.65px;
}

.home-nav-link-to {
    padding: 7px;
    font-weight: 600;
    font-size: 35px;
    font-family: 'Cooper Hewitt', 'Inter', Arial, Helvetica, sans-serif;;
}

.home-navigation {
    display: flex;
    justify-content: center;
    padding: 20px;
    background: transparent;
    position: static;
    z-index: 100000;
    box-shadow: none;
    max-width: 1000px;
}

.home-nav-link-home {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px auto
}

.home-nav-link-home:after {
    content: "";
    width: 205px;
    height: 9px;
    background: #575757;
    position: absolute;
    bottom: 37px;
}

.home-navigation-text .navbar-toggler {
    position: absolute;
    right: 0;
    top: 20px;
    right: 20PX;
    border: none;
    height: 44px;
    border-radius: 0;
}

.home-nav-logo {
    width: 205px;
    height: 217px;
    font-weight: bold;
}

.home-navigation-text {
    font-size: 35px;
    color: var(--black);
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 400;
    margin: auto;
}

.home-nav-social-icons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;
    font-size: 35px;
}

.home-nav-social-icons svg {
    width: 40px;
    height: 40px;
}

.home-nav-social-icons path, .home-nav-social-icons polygon, .home-nav-social-icons circle {
    fill: white;
}

.social-icons-a {
    color: white;
    padding: 0 20px;
}

.navbar-nav {
    left: -400px;
    transition-duration: inherit;
} 

@media (min-width: 540px) {

    .home-nav-link-container {
        text-align: center;
    }

}

@media (min-width: 768px) {

    .home-navigation {
        position: static;
        box-shadow: none;
        background: transparent;
    }

    .home-navigation-text .navbar-toggler {
        top: 50px;
        right: 30px;
    }

    .home-nav-social-icons {
        width: 100%;
    }

    .home-nav-link-home:hover {
        color: initial;
    }

}

@media (min-width: 992px) {

    .navbar-light .navbar-nav .nav-link {
        color: var(--black);
    }

    .home-navbar-link-container {
        flex-direction: row;
    }

    .home-navigation {
        margin: 40px auto;
        width: 100%;
    }

    .home-nav-logo {
        margin: auto auto auto 0;
    }

    .home-nav-link-home {
        margin: initial;
    }

    .home-navigation-text {
        display: flex;
        width: 100%;
    }

    .home-navbar-nav {
        background: transparent;
        width: 100%;
        align-items: flex-end;
    }

    .navbar-expand-lg .home-navbar-nav.navbar-nav {
        flex-direction: column;
    } 

    /* Override bootstrap height 0 that they inject inline into the DOM */
     .navbar-collapse {
        height: auto !important;
        position: static;
    } 

    .home-nav-link-container {
        flex-direction: row;
    }

    .home-navigation .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 1rem;
    } 

    .home-nav-link-to {
        padding: 0 .5rem .5rem .5rem;
        font-size: 25px; 
        color: var(--black);
    }

    .home-nav-social-icons {
        margin-top: 0;
        justify-content: center;
        width: 100%;
    }

    .home-nav-social-icons svg {
        height: 30px;
        width: 30px;
        opacity: .75;
    }

    .home-nav-social-icons path, .home-nav-social-icons polygon, .home-nav-social-icons circle {
        fill: var(--black);
    }

}
