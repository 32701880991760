.footer {
    position: absolute;
    width: 100%; 
    height: 11.5rem;
    overflow: hidden;
    bottom: -150px;
}

.footer-span {
    position: absolute;
    font-family:'Cooper Hewitt', 'Inter', 'Arial';
    text-align: center;
    left: 0;
    right: 0;
    top: 80%;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.footer-img {
    width: 100%;
    max-width: 500px;
    align-self: center;
    margin: auto;
}

@media (min-width: 580px) {

    .footer {
        height: 15.5rem;
    }

    .footer-span {
        top: 90%;
    }
}
