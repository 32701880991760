path {
    fill: #e8e8e8;
}
.technology-list-section {
    background: blue;
    background:#2c3e50;
    background:linear-gradient(
         rgba(0, 0, 0, 0.25),
         rgba(0, 0, 0, 0.25) ),
          url('../assets/mural.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    background-position: center;
}

.tech-list-ul {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    list-style: none;
    padding:  40px 0;
    max-width: 1150px;
    margin: auto;  
}

.tech-svg {
    margin: 20px;
}

.tech-svg svg {
    height: 125px;
    max-width: 120px;
}

@media (min-width: 580px) {
    .tech-list-ul {
        justify-content: space-evenly;
    }

}

@media (min-width: 768px) {

    .tech-list-ul {
        grid-template-columns: auto auto auto auto auto;     
    }

    .tech-svg svg {
        width: 100%;
        height: 110px;
    }
  
}